import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import responsible, { Responsible, Value } from '../model/responsible';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.Responsible');
export interface BackendResponsible {
  getResponsible(id: string): AxiosPromise<Responsible>;
  getResponsibles: (searchParams: SearchParams) => AxiosPromise<Value>;
  deleteResponsible(id: string): AxiosPromise;
  updateResponsible(Responsible: Responsible): AxiosPromise<any>;
}

export const defaultBackendResponsible: BackendResponsible = {
  getResponsible(id: string): AxiosPromise<Responsible> {
    let url = `${URLS.responsibleOdata}/${id}`;
    return instance.get<Responsible>(url);
  },
  getResponsibles(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.responsibleOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getResponsibles${url}`);
    return instance.get<Value>(url);
  },

  deleteResponsible(id: string): AxiosPromise {
    logger.debug('deleteResponsible');
    return instance.delete(`${URLS.responsible}/${id}`);
  },
  updateResponsible(Responsible: Responsible): AxiosPromise<any> {
    logger.debug('updateResponsible');
    return instance.put<Responsible>(`${URLS.responsible}/update`, responsible.toAPI(Responsible));
  },
};
