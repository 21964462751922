import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class DialogBoxComponent extends Vue {
  dialog = false;

  messageBox = false;

  resolve: any = null;
  reject: any = null;

  message: string = '';
  title: string = '';
  options = {
    color: 'white',
    width: 390,
    zIndex: 200,
  };

  openConfirm(title: string, message: string, options?: object) {
    this.configureOptions(title, message, options);
    return new Promise((resolve: any, reject: any) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  openMessageBox(title: string, message: string, options?: object) {
    this.messageBox = true;
    this.configureOptions(title, message, options);
  }

  private configureOptions(title: string, message: string, options?: object) {
    this.dialog = true;
    this.title = title;
    this.message = message;
    this.options = Object.assign(this.options, options);
  }

  private agree() {
    if (!this.messageBox) {
      this.resolve(true);
    }
    this.dialog = false;
    this.messageBox = false;
  }
  private cancel() {
    this.resolve(false);
    this.dialog = false;
    this.messageBox = false;
  }
}
