import store from '@/shared/store/index';
import { User } from '@/shared/model/user';
import { AUTHORITIES } from '@/shared/model/authorities';
import { msalInstance } from 'vue-msal-browser';

export default class AuthorityUtils {
  public static isEmptyAccount() {
    return store.getters['auth/isLoggedIn'] == false;
  }
  public static isEmptyAzureAccount() {
    return !(msalInstance && msalInstance.getAllAccounts()[0]);
  }
  public static async ensureAccountDetails() {
    if (this.isEmptyAccount()) {
      // wait for account details to be loaded
      await store.dispatch('auth/loadAccountDetails');
    }
  }
  public static getAccountInfo(): User {
    return store.getters['auth/getUser'];
  }

  public static async getApiInfo() {
    if (!store.getters['apiInfo']) {
      await store.dispatch('load_info');
    }
  }

  public static hasAnyRole(roles: string[]) {
    const user: User = store.getters['auth/account'];
    if (user.authorities == undefined) {
      return false;
    }

    roles = roles ? roles : Object.keys(AUTHORITIES);

    return user && user.authorities.some((authorities) => roles.includes(authorities));
  }

  public static isAdmin() {
    return store.getters['auth/isAdmin'];
  }

  public static isManager() {
    return store.getters['auth/isManager'];
  }
}
