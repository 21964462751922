import { SaveListState } from './types';

export const state = (): SaveListState => initialState();

export const initialState = (): SaveListState => ({
  saveLists: {
    //TODO remove when was made backend
    items: [],
    isLoading: false,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ['CreatedAt'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: ['Name', 'Title', 'Warengruppen', 'CreatedBy'],
    },
  },
});
