import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import article, { Article, Value } from '../model/article';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.Article');
export interface BackendArticle {
  getArticlesWarengruppe(): AxiosPromise<any>;
  getArticlesAllSuppliers(): AxiosPromise<any>;
  getArticle(id: string): AxiosPromise<Article>;
  getArticles: (searchParams: SearchParams) => AxiosPromise<Value>;
  getArticlesPreisCount: () => AxiosPromise<number>;
  getArticlesTodayCount: () => AxiosPromise<number>;
  getArticleLastUpdateDate: () => AxiosPromise<any>;
  deleteArticle(id: string): AxiosPromise;
  updateArticle(Article: Article): AxiosPromise<any>;

  getArticlesReport(data: any): AxiosPromise<any>;
  getArticlesSuppliersFiltered(data: any): AxiosPromise<any>;

  getArticlesReportExcelFromSystem(type: string): AxiosPromise<any>;

  uploadExcel(payload: any): AxiosPromise<any>;
}

export const defaultBackendArticle: BackendArticle = {
  uploadExcel(payload: any): AxiosPromise<any> {
    return instance.post<any>(`${URLS.article}/UploadExcel`, payload);
  },
  getArticlesWarengruppe(): AxiosPromise<any> {
    let url = `${URLS.article}/GetDistinctWarengruppen`;
    return instance.get<any>(url);
  },
  getArticlesAllSuppliers(): AxiosPromise<any> {
    let url = `${URLS.article}/GetAllDistinctSuppliers`;
    return instance.get<any>(url);
  },
  getArticle(id: string): AxiosPromise<Article> {
    let url = `${URLS.articleOdata}/${id}`;
    return instance.get<Article>(url);
  },
  getArticlesPreisCount(): AxiosPromise<number> {
    let url = `${URLS.article}/GetPreisstatusCount`;
    return instance.get<number>(url);
  },
  getArticlesTodayCount(): AxiosPromise<number> {
    let url = `${URLS.article}/GetTodayCount`;
    return instance.get<number>(url);
  },
  getArticleLastUpdateDate(): AxiosPromise<any> {
    let url = `${URLS.article}/GetLastUpdateDate`;
    return instance.get<any>(url);
  },
  getArticles(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.articleOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getArticles${url}`);
    return instance.get<Value>(url);
  },

  deleteArticle(id: string): AxiosPromise {
    logger.debug('deleteArticle');
    return instance.delete(`${URLS.article}/${id}`);
  },
  updateArticle(Article: Article): AxiosPromise<any> {
    logger.debug('updateArticle');
    return instance.put<Article>(`${URLS.article}/update`, article.toAPI(Article));
  },
  getArticlesReport(data: any): AxiosPromise<any> {
    logger.debug('updateArticle');
    return instance.put<any>(`${URLS.article}/GetArticleReport`, data, {
      responseType: 'blob',
    });
  },
  getArticlesSuppliersFiltered(data: any): AxiosPromise<any> {
    logger.debug('getArticlesSuppliersFiltered');
    return instance.put<any>(`${URLS.article}/GetDistinctSuppliers`, data);
  },

  getArticlesReportExcelFromSystem(reportType: string): AxiosPromise<any> {
    logger.debug('getArticlesReportExcelFromSystem');
    // return instance.put<Article>(`${URLS.article}/GetExcelReport`, article.toAPI(Article));
    let url = `${URLS.article}/GetExcelReportFromSystem`;
    return instance.put<any>(url, reportType, {
      responseType: 'blob',
    });
  }
};
