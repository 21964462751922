import { MutationTree } from 'vuex';
import { ArticleState } from './types';
import Vue from 'vue';
import article, { Article, Value } from '@/shared/model/article';

export const mutations: MutationTree<ArticleState> = {
  setArticles(state, payload: Value) {
    state.articles.items = payload.value.map((x) => article.parse(x));
    state.articles.total = payload['@odata.count'] || 0;
    state.articles.isLoading = false;
  },
  setArticlesTotal(state, payload: number) {
    state.articles.total = payload;
  },
  setArticlesIsLoading(state, payload: boolean) {
    state.articles.isLoading = payload;
  },

  setArticlesWarengruppe(state, payload: any) {
    state.articlesWarengruppe = payload;
  },
  setArticlesSuppliers(state, payload: any) {
    state.articlesSuppliers = payload;
  },
};
