import { GetterTree } from 'vuex';
import { ReportDownloadState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ReportDownloadState, RootState> = {
  ['getReportDownloads']: (state) => state.reportDownloads,
  ['getReportDownloadsIsLoading']: (state) => state.reportDownloads?.isLoading,
  ['getReportDownloadsTotal']: (state) => state.reportDownloads?.total,
  ['getReportDownloadsSearchParams']: (state) => state.reportDownloads?.searchParams,
};
