export interface ArticleMarker {
  id: number; // int
  name: string; // string
}

export interface Value {
  value: ArticleMarker[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<ArticleMarker>): any {
  return {
    Id: data?.id || 0,
    Name: data?.name || '',
  };
}

function parse(data?: Partial<ArticleMarker>): ArticleMarker {
  return {
    id: data?.id || 0,
    name: data?.name || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
