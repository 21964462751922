import { ActionTree } from 'vuex';
import { ResponsibleState } from './types';
import { RootState } from '../../types';
import { defaultBackendResponsible } from '@/shared/backend/responsible';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import responsible, { Value, Responsible } from '@/shared/model/responsible';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.responsibles');
export const actions: ActionTree<ResponsibleState, RootState> = {
  getResponsibles({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setResponsiblesIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getResponsiblesSearchParams;
    return defaultBackendResponsible
      .getResponsibles(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setResponsibles', response.data);
        commit('setResponsiblesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setResponsiblesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getResponsible({ commit, dispatch }, id: string) {
    return defaultBackendResponsible
      .getResponsible(id)
      .then((response: AxiosResponse<Responsible>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateResponsible({ commit, dispatch }, file: Responsible) {
    return defaultBackendResponsible
      .updateResponsible(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Responsible;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.responsible_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteResponsible({ commit, dispatch }, id: string) {
    return defaultBackendResponsible
      .deleteResponsible(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Responsible;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
