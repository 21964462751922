import { MutationTree } from 'vuex';
import { ReportDownloadState } from './types';
import Vue from 'vue';
import reportDownload, { ReportDownload, Value } from '@/shared/model/reportDownload';

export const mutations: MutationTree<ReportDownloadState> = {
  setReportDownloads(state, payload: Value) {
    state.reportDownloads.items = payload.value.map((x) => reportDownload.parse(x));
    state.reportDownloads.total = payload['@odata.count'] || 0;
    state.reportDownloads.isLoading = false;
  },
  setReportDownloadsTotal(state, payload: number) {
    state.reportDownloads.total = payload;
  },
  setReportDownloadsIsLoading(state, payload: boolean) {
    state.reportDownloads.isLoading = payload;
  },
};
