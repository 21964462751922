import { ActionTree } from 'vuex';
import { ArticleState } from './types';
import { RootState } from '../../types';
import { defaultBackendArticle } from '@/shared/backend/article';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import article, { Value, Article } from '@/shared/model/article';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import DownloadUtils from '@/shared/utils/DownloadUtils';

const logger = new Logger('actions.articles');
export const actions: ActionTree<ArticleState, RootState> = {
  async uploadExcel({ commit }, payload: { file: any; targetFolder: string }) {
    try {
      const data = new FormData();
      data.append('File', payload.file);
      // data.append('TargetFolder', payload.targetFolder);

      return await defaultBackendArticle.uploadExcel(data).then((response: AxiosResponse<any>) => {
        const result = response.data.$values ? response.data.$values : response.data;
        return result;
      });
    } catch (e) {
      module_utils.error('error', commit, e, logger);
      throw e;
    }
  },

  getArticlesWarengruppe({ commit, dispatch, getters, rootGetters }, payload?: any) {
    return defaultBackendArticle
      .getArticlesWarengruppe()
      .then((response: AxiosResponse<any>) => {
        logger.log('getArticlesWarengruppe response :>> ', response);
        const result = response.data.$values ? response.data.$values : response.data;
        commit('setArticlesWarengruppe', result);
        return result;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getArticlesSuppliersFiltered({ commit, dispatch, getters, rootGetters }, payload?: any) {
    return defaultBackendArticle
      .getArticlesSuppliersFiltered(payload)
      .then((response: AxiosResponse<any>) => {
        const result = response.data.$values ? response.data.$values : response.data;
        commit('setArticlesSuppliers', result);
        return result;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getArticlesReport({ commit, dispatch, getters, rootGetters }, payload?: any) {
    return defaultBackendArticle
      .getArticlesReport(payload)
      .then((response: any) => {
        if (response.headers['content-disposition']) {
          const fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
          const type = response.headers['content-type'];
          console.log('type :>> ', type);
          const blob = new Blob([response.data], { type: type });
          console.log('blob :>> ', blob);
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          console.log('url :>> ', url);
          window.URL.revokeObjectURL(url);
        }
        console.log('response.data :>> ', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getExcelReportFromSystem({ commit, dispatch, getters, rootGetters }, payload?: any) {
    return defaultBackendArticle
      .getArticlesReportExcelFromSystem(payload)
      .then((response: any) => {
        if (response.headers['content-disposition']) {
          const fileName = DownloadUtils.getFileName(response.headers['content-disposition']);
          const type = response.headers['content-type'];
          console.log('type :>> ', type);
          const blob = new Blob([response.data], { type: type });
          console.log('blob :>> ', blob);
          const url = DownloadUtils.createDownloadLink(blob, fileName);
          console.log('url :>> ', url);
          window.URL.revokeObjectURL(url);
        }
        console.log('response.data :>> ', response.data);
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },


  getArticles({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setArticlesIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getArticlesSearchParams;
    return defaultBackendArticle
      .getArticles(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setArticles', response.data);
        commit('setArticlesIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setArticlesIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getArticle({ commit, dispatch }, id: string) {
    return defaultBackendArticle
      .getArticle(id)
      .then((response: AxiosResponse<Article>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getArticlesPreisCount({ commit, dispatch }) {
    return defaultBackendArticle
      .getArticlesPreisCount()
      .then((response: AxiosResponse<number>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getArticlesTodayCount({ commit, dispatch }) {
    return defaultBackendArticle
      .getArticlesTodayCount()
      .then((response: AxiosResponse<number>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getArticleLastUpdateDate({ commit, dispatch }) {
    return defaultBackendArticle
      .getArticleLastUpdateDate()
      .then((response: AxiosResponse<number>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateArticle({ commit, dispatch }, file: Article) {
    return defaultBackendArticle
      .updateArticle(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Article;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.article_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteArticle({ commit, dispatch }, id: string) {
    return defaultBackendArticle
      .deleteArticle(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: Article;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
