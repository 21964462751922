import { Component, Vue } from 'vue-property-decorator';
import DialogBoxComponent from '@/components/dialogBox/dialogBox';
import { Action, Getter, namespace, State } from 'vuex-class';
import { Logger } from 'fsts';
import { ROUTES } from '@/router/routesEnum';

const logger = new Logger('app');
const authModule = namespace('auth');

@Component({
  components: {},
})
export default class App extends Vue {
  public $refs!: Vue['$refs'] & {
    dialogBox: DialogBoxComponent;
  };
  @State('snackbar')
  private snackbar!: any; //Snackbar
  @Action('hideSnackbar') private releaseSnackbar: any;

  @authModule.Getter('isLoggedIn')
  private isLoggedIn!: boolean;
  @authModule.Getter('getUser')
  private user!: any;
  @authModule.Getter('azureUser')
  private azureUser!: any;
  @authModule.Action('logout')
  private logoutAction: any;
  @authModule.Action('updateAzureData')
  private updateAzureData: any;

  get mobile() {
    return this.$vuetify.breakpoint.mdAndDown;
  }

  get hasAzureUser() {
    return this.azureUser?.name;
  }

  drawer = false;
  toggleMini = true;
  maxHeight = 32;
  maxWidth = 32;

  leftMenuItems: {
    src: string;
    translateId?: string;
    to?: string;
  }[] = [
    {
      src: 'ico_home',
      translateId: 'Home',
      to: '/',
    },
    {
      src: 'ico_list',
      translateId: 'lists',
      to: '/lists',
    },
    {
      src: 'ico_system',
      translateId: 'system',
      to: '/system',
    },
  ];

  async created() {
    logger.log('process.env :>> ', process.env);
    //!! (LIX-106) comment until correct `AzureApp` data (`TenantId` + `ClientId)
    await this.$msal
      .authenticateRedirect()
      .then(async (result: any) => {
        logger.log('Azure logging :>> ', result);
        await this.updateAzureData(result);
        this.$router.push({ name: ROUTES.home });
      })
      .catch((err: any) => {});
  }

  mounted() {
    // placing a global component in app.vue.html and adding ref options (e.g. <myComponent ref="mycomp"></myComponent>)
    // then make a ref global:
    this.$confirm.open = this.$refs.dialogBox.openConfirm;
    this.$message.open = this.$refs.dialogBox.openMessageBox;
  }

  async logout() {
    // await this.logoutAction();
    await this.$msal.logoutRedirect();

    this.$router.push({ name: ROUTES.login });
  }
}
