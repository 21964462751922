import { AxiosPromise } from 'axios';
import { instance } from '.';
import { Credentials } from '../model/smallPayloadModels/credentials';
import { URLS } from './index';

export interface BackendAccount {
  authenticate: (
    username: string,
    password: string,
    rememberMe?: boolean
  ) => AxiosPromise<{
    userName: string;
    userTypeName: string;
    companyId: string;
    userId: string;
    refresh_token: string;
  }>;

  getMyAccount: () => AxiosPromise<any>;
  logout: () => AxiosPromise;
}

export const defaultBackendAccount: BackendAccount = {
  authenticate(
    username: string,
    password: string,
    rememberMe?: boolean
  ): AxiosPromise<{
    userName: string;
    userTypeName: string;
    companyId: string;
    userId: string;
    refresh_token: string;
  }> {
    const credentials: Credentials = {
      password,
      username,
      rememberMe,
      authorities: [],
      emailConfirmed: false,
      inactivityMinutes: 0,
    };
    return instance.post<any>(`${URLS.authenticate}`, credentials);
  },

  getMyAccount(): AxiosPromise<any> {
    return instance.get<any>(`${URLS.account}/GetMe`);
  },
  logout(): AxiosPromise {
    return instance.post(`${URLS.account}/logout`);
  },
};
