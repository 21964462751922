export interface Responsible {
  id: number; // int
  initials: string; // string
}

export interface Value {
  value: Responsible[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Responsible>): any {
  return {
    Id: data?.id || 0,
    Initials: data?.initials || '',
  };
}

function parse(data?: Partial<Responsible>): Responsible {
  return {
    id: data?.id || 0,
    initials: data?.initials || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
