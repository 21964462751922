import { CONST } from '../utils/Constants';
import sl, { SaveList } from './saveList';

export interface ReportDownload {
  id: string; // int
  createdOn: string; // System.DateTime?
  createdBy: string; // string
  outputType: string; // string
  saveListId: string; // System.Guid
  saveList: SaveList; //

  // properties from `SaveList` model, returned from backend DTO
  name: string; // string
  listType: string; // string
  articleMarker: string; // string
  sort: string; // string
}

export interface Value {
  value: ReportDownload[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<ReportDownload>): any {
  return {
    Id: data?.id || undefined,
    CreatedOn: data?.createdOn || '',
    CreatedBy: data?.createdBy || '',
    OutputType: data?.outputType || '',
    SaveListId: data?.saveListId || '',
    SaveList: data?.saveList || undefined,

    // properties from `SaveList` model, returned from backend DTO
    Name: data?.name || '',
    ListType: data?.listType || '',
    ArticleMarker: data?.articleMarker || '',
    Sort: data?.sort || '',
  };
}

function parse(data?: Partial<ReportDownload>): ReportDownload {
  return {
    id: data?.id || CONST.emptyGuid,
    createdOn: data?.createdOn || '',
    createdBy: data?.createdBy || '',
    outputType: data?.outputType || '',
    saveListId: data?.saveListId || '',
    saveList: data?.saveList || sl.parse({}),

    // properties from `SaveList` model, returned from backend DTO
    name: data?.name || '',
    listType: data?.listType || '',
    articleMarker: data?.articleMarker || '',
    sort: data?.sort || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
