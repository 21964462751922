import { ReportDownloadState } from './types';

export const state = (): ReportDownloadState => initialState();

export const initialState = (): ReportDownloadState => ({
  reportDownloads: {
    items: [],
    isLoading: false,
    total: 20,
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 20,
        sortBy: ['createdOn'],
        sortDesc: [true],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [],
    },
  },
});
