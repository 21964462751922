import { MutationTree } from 'vuex';
import { ArticleMarkerState } from './types';
import Vue from 'vue';
import articleMarker, { ArticleMarker, Value } from '@/shared/model/articleMarker';

export const mutations: MutationTree<ArticleMarkerState> = {
  setArticleMarkers(state, payload: Value) {
    state.articleMarkers.items = payload.value.map((x) => articleMarker.parse(x));
    state.articleMarkers.total = payload['@odata.count'] || 0;
    state.articleMarkers.isLoading = false;
  },
  setArticleMarkersTotal(state, payload: number) {
    state.articleMarkers.total = payload;
  },
  setArticleMarkersIsLoading(state, payload: boolean) {
    state.articleMarkers.isLoading = payload;
  },
};
