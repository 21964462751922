import { GetterTree } from 'vuex';
import { ArticleMarkerState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ArticleMarkerState, RootState> = {
  ['getArticleMarkers']: (state) => state.articleMarkers,
  ['getArticleMarkersIsLoading']: (state) => state.articleMarkers?.isLoading,
  ['getArticleMarkersTotal']: (state) => state.articleMarkers?.total,
  ['getArticleMarkersSearchParams']: (state) => state.articleMarkers?.searchParams,
};
