import { AuthState } from './types';

export const state = (): AuthState => initialState();

export const initialState = (): AuthState => ({
  account: null,
  azureData: undefined,
  accountId: undefined,
  error: false,
});
