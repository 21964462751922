export default class DateUtils {
  // DB ISO date come in `2022-11-23T13:22:00.001426` format in UTC 0 timezone
  public static dbUtcDateTimeToLocalTime = (date: string): string => {
    if (!date) return '...';

    const dbDatePart = date.split('T')[0];
    const dotDate = DateUtils.isoDateStringToDots(dbDatePart); // ISO `YYYY-MM-DD` date format to `DD.MM.YYYY`

    const inputDate = new Date(date);
    const inputDateInMilliSeconds = inputDate.getTime();
    const tzOffset = date.includes('Z') ? 0 : new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    // (LIX-155) WITH `Z` (0 time zone) from Linux server the `new Date(date)` will return local time automatically, so no need fo offset

    // !! `toISOString()` will reset added offset back to UTC time as in database so need to parse time separately
    const localTime = new Date(inputDateInMilliSeconds - tzOffset); // get REAL time for user's timezone
    const isLocalDateToday = inputDate.getDate() == localTime.getDate();

    const hours = isLocalDateToday ? localTime.getHours() : 23; // if local timezone in the TOMORROW then just show 23 as hours
    const time = `${String(hours).padStart(2, '0')}:${String(localTime.getMinutes()).padStart(2, '0')}:${String(
      localTime.getSeconds()
    ).padStart(2, '0')}`;
    const myDate = `${dotDate} ${time}`;
    return myDate;
  };

  public static formatDateTimeISO = (date: any): string | undefined => {
    if (!date) return undefined;

    const tzOffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    const localISOTime = new Date(Date.now() - tzOffset).toISOString().slice(0, -5).replace('T', ' '); // => '2020-11-10 21:45:24'
    return localISOTime;
  };

  public static toDateString = (date: any): string | undefined => {
    if (!date) return '-';

    const tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    const localISOTime = new Date(date - tzoffset); // => '2020-11-10 21:45:24'
    return localISOTime.getMonth() + '/' + localISOTime.getDate() + '/' + localISOTime.getFullYear();
  };

  /**
   * Format date (most likely ISO) to `DD.MM.YYYY` (DE) or `DD/MM/YYYY`(EN)
   * @param date
   * @param locale (portal locale [now `de` or `en`])
   */
  public static formatDateTimeWithLocale = (date: any, locale: string = 'de'): string | undefined => {
    if (!date) return undefined;

    const time = date.toString().split(' ')[1]; // orginal time from the backend (no offsets)
    const dateOnly = DateUtils.formatDateIsoNoOffset(date);
    let resultDate = DateUtils.isoDateStringToDots(dateOnly!);

    if (locale == 'en') {
      resultDate = resultDate!.replaceAll('.', '/');
    }
    // date + time (without offset)
    return `${resultDate} ${time ? time : ''}`;
  };

  // (EGRUP-156) when enter `1-1-22` in `date-field` input which should be `01.01.2022` in DatePicker, then we get `31.12.2021 21:00` for our timezone `+3` without ignoring Offset
  public static formatDateIsoNoOffset = (date: any): string | undefined => {
    if (!date) return undefined;

    date = DateUtils.switchMonthAndDay(date);
    const dateObj = new Date(date);

    // needs such logic with many `+` and  `slice(-2)` to handle correctly both 1 and 2 digit months and days
    // like `1-1-21` will become `2021-01-01`, but without `slice(-2)` or another additional logic from `11-12-21` we will get `2021-012-011` which return ERROR when try to open for datepicker
    const resultDate =
      dateObj.getFullYear() +
      '-' +
      ('0' + (dateObj.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + dateObj.getDate()).slice(-2);
    return resultDate;
  };

  /**
   * Convert ISO `YYYY-MM-DD` date format to `DD.MM.YYYY`
   * @param date
   */
  public static isoDateStringToDots(date: string): string {
    let dateParts = date.toString().split(/[-]/);
    let year = dateParts[0];
    let month = dateParts[1];
    let day = dateParts[2];

    let isoDate = `${day}.${month}.${year}`;
    return isoDate;
  }

  /// convert `DD.MM.YYYY` to `YYYY-MM-DD`
  public static dotDateStringToISO(date: string): string {
    let dateParts = date.toString().split(/[.]/);
    let year = +dateParts[2];
    let month = +dateParts[1];
    let day = +dateParts[0];

    let isoDate = `${year}-${month}-${day}`;
    return isoDate;
  }

  public static isIsoStart(date: string) {
    let isoBeginYYYY = /\d{4}-/;
    return isoBeginYYYY.test(date);
  }

  /**
   * Convert default JS format `MM.DD.YYYY` to `DD.MM.YYYY`
   * @param date (date value)
   */
  public static switchMonthAndDay(date: string | Date): Date {
    if (this.isIsoStart(date.toString())) {
      // check ISO date format from datePicker (YYYY-MM-DD)
      return new Date(date);
    }

    // Default JS format is MM/DD/YYYY
    let dateParts = date.toString().split(/[.\-/]/);
    let year = +dateParts[2];
    let month = +dateParts[1];
    let day = +dateParts[0];

    // to avoid get `01.01.1921` from `1.1.21`
    if (year < 50) {
      year += 2000;
    }
    // month is 0-based, that's why we need dataParts[1] - 1
    var dateValue = new Date(year, month - 1, day);
    return dateValue;
  }
}
