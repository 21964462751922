var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-app',[_c('v-app-bar',{attrs:{"flat":"","app":""}},[(_vm.mobile)?_c('v-app-bar-nav-icon',{staticClass:"ml-0",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.drawer ? 'mdi-close' : 'mdi-menu')+" ")])],1):_vm._e(),_c('v-toolbar-title',{staticClass:"pl-0"},[_c('router-link',{attrs:{"tag":"div","to":"/","exact":""}},[_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}})],1)],1),_c('v-spacer'),(_vm.isLoggedIn)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('d4y-btn',_vm._g({staticClass:"ma-0 text-capitalize",attrs:{"text":""}},on),[_c('span',[_vm._v(_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName))]),_c('v-icon',[_vm._v("mdi-account-circle")])],1)]}}],null,false,1557108998)},[_c('v-list',[(_vm.isLoggedIn && false)?_c('v-list-item',{attrs:{"to":"/editProfile"}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-edit")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('edit_profile'))+" ")])],1)],1):_vm._e(),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoggedIn),expression:"isLoggedIn"}],on:{"click":function($event){$event.stopPropagation();return _vm.logout.apply(null, arguments)}}},[_c('v-list-item-action',[_c('v-icon',{staticClass:"action_logout"},[_vm._v("mdi-exit-to-app")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('logout')))])],1)],1)],1):_vm._e()],1),_c('v-navigation-drawer',{staticClass:"left-menu justify-space-between",attrs:{"permanent":!_vm.mobile,"app":"","mini-variant":_vm.toggleMini,"mini-variant-width":"110"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"mb-5"},[(_vm.hasAzureUser)?_c('div',{staticClass:"grey--text"},[_vm._v(_vm._s(_vm.azureUser.name))]):_vm._e(),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('d4y-btn',_vm._g({staticClass:"ma-0",attrs:{"text":""}},on),[_c('v-img',{attrs:{"contain":"","height":40,"max-height":40,"max-width":40,"src":require('@/assets/left_menu/ico_profile.svg')}})],1)]}}])},[_c('v-list',[_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasAzureUser),expression:"hasAzureUser"}],staticStyle:{"height":"40px","width":"170px"},on:{"click":function($event){$event.stopPropagation();return _vm.logout.apply(null, arguments)}}},[_c('v-list-item-action',[_c('v-icon',{staticClass:"action_logout"},[_vm._v("mdi-exit-to-app")])],1),_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('logout')))])],1)],1)],1)],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":""}},[_c('div',{staticClass:"app-logo"},[_c('img',{staticClass:"header__logo",attrs:{"src":require("@/assets/logo.png"),"alt":"Ring logo"}})]),_vm._l((_vm.leftMenuItems),function(item,i){return _c('div',{key:i},[_c('v-list-item',{attrs:{"ripple":false,"link":"","to":item.to},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-icon',{staticClass:"d-flex flex-wrap justify-center"},[_c('v-img',{attrs:{"contain":"","height":32,"max-height":_vm.maxHeight,"max-width":_vm.maxWidth,"src":require('@/assets/left_menu/'+item.src+(active ? '_active': '')+'.svg')}}),(item.translateId)?_c('div',{staticClass:"full-width",class:[active ? 'white--text': 'grey--text']},[_vm._v(" "+_vm._s(_vm.$t(item.translateId))+" ")]):_vm._e()],1)]}}],null,true)})],1)})],2)],1),_c('v-main',[_c('router-view')],1),(false)?_c('v-footer',{attrs:{"height":"auto","color":"white","absolute":"","app":""}},[_c('v-row',{staticClass:"navy-blue-lunacy--text subtitle-2 font-weight-regular",attrs:{"no-gutters":""}},[_c('span',{staticClass:"px-2 pl-16"},[_vm._v("©2022 "),_c('a',{staticClass:"footer__link navy-blue-lunacy--text",attrs:{"href":"#"}},[_vm._v("VME ListEx")])]),_c('span',{staticClass:"px-2"},[_vm._v(" Version: 0.xxx")]),_c('span',{staticClass:"spacer"}),_c('span',{staticClass:"d-flex ml-0 ml-sm-3"},[_c('a',{staticClass:"footer__link navy-blue-lunacy--text",attrs:{"href":"#"}},[_vm._v(_vm._s(_vm.$t('support_link')))])])])],1):_vm._e(),_c('v-snackbar',{attrs:{"top":"","color":_vm.snackbar.color,"timeout":_vm.snackbar.duration},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" "),_c('d4y-btn',{attrs:{"dark":"","text":""},on:{"click":function($event){return _vm.releaseSnackbar()}}},[_vm._v("Ok")])],1),_c('dialogbox',{ref:"dialogBox"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }