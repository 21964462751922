import { ArticleMarkerState } from './types';

export const state = (): ArticleMarkerState => initialState();

export const initialState = (): ArticleMarkerState => ({
  articleMarkers: {
    //TODO remove when was made backend
    items: [],
    isLoading: false,
    total: 2, //TODO remove when was made backend
    searchParams: {
      dataOption: {
        page: 1,
        itemsPerPage: 1000,
        sortBy: [],
        sortDesc: [false],
        groupBy: [],
        groupDesc: [],
        multiSort: false,
        mustSort: false,
      },
      filter: '',
      orClauseFieldsIds: [''],
    },
  },
});
