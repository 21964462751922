import { ActionTree } from 'vuex';
import { ModelMarkerState } from './types';
import { RootState } from '../../types';
import { defaultBackendModelMarker } from '@/shared/backend/modelMarker';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import modelMarker, { Value, ModelMarker } from '@/shared/model/modelMarker';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.modelMarkers');
export const actions: ActionTree<ModelMarkerState, RootState> = {
  getModelMarkers({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setModelMarkersIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getModelMarkersSearchParams;
    return defaultBackendModelMarker
      .getModelMarkers(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setModelMarkers', response.data);
        commit('setModelMarkersIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setModelMarkersIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getModelMarker({ commit, dispatch }, id: string) {
    return defaultBackendModelMarker
      .getModelMarker(id)
      .then((response: AxiosResponse<ModelMarker>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateModelMarker({ commit, dispatch }, file: ModelMarker) {
    return defaultBackendModelMarker
      .updateModelMarker(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ModelMarker;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.modelMarker_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteModelMarker({ commit, dispatch }, id: string) {
    return defaultBackendModelMarker
      .deleteModelMarker(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ModelMarker;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
