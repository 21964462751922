import { GetterTree } from 'vuex';
import { ResponsibleState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ResponsibleState, RootState> = {
  ['getResponsibles']: (state) => state.responsibles,
  ['getResponsiblesIsLoading']: (state) => state.responsibles?.isLoading,
  ['getResponsiblesTotal']: (state) => state.responsibles?.total,
  ['getResponsiblesSearchParams']: (state) => state.responsibles?.searchParams,
};
