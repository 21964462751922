import { MutationTree } from 'vuex';
import { ResponsibleState } from './types';
import Vue from 'vue';
import responsible, { Responsible, Value } from '@/shared/model/responsible';

export const mutations: MutationTree<ResponsibleState> = {
  setResponsibles(state, payload: Value) {
    state.responsibles.items = payload.value.map((x) => responsible.parse(x));
    state.responsibles.total = payload['@odata.count'] || 0;
    state.responsibles.isLoading = false;
  },
  setResponsiblesTotal(state, payload: number) {
    state.responsibles.total = payload;
  },
  setResponsiblesIsLoading(state, payload: boolean) {
    state.responsibles.isLoading = payload;
  },
};
