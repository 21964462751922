import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import i18n from '../i18n';
import D4yAutocomplete from '../components/_common/inputs/d4y-autocomplete.vue';
import D4yBtn from '../components/_common/inputs/d4y-btn.vue';
import D4ySelect from '../components/_common/inputs/d4y-select.vue';
import D4yTextField from '../components/_common/inputs/d4y-text-field.vue';
import D4yTextarea from '../components/_common/inputs/d4y-textarea.vue';
import Password from '../components/_common/password/Password.vue';

Vue.use(Vuetify);

// our custom components based on Vuetify
Vue.component('d4y-autocomplete', D4yAutocomplete);
Vue.component('d4y-btn', D4yBtn);
Vue.component('d4y-select', D4ySelect);
Vue.component('d4y-text-field', D4yTextField);
Vue.component('d4y-textarea', D4yTextarea);
Vue.component('password', Password);

// !! TODO: use `a la carte` components to improve performance (https://stackoverflow.com/questions/46680467/what-is-a-la-carte-components-should-i-use-it) + (https://vuetifyjs.com/en/features/treeshaking/#manually-importing)

export default new Vuetify({
  lang: {
    current: 'en',
    t: (key: any, ...params: any) => i18n.t(key, params).toString(),
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      // !! TODO: didn't decide about color scheme so took it from previous project
      light: {
        primary: '#5b9bd5',
        secondary: '#3342ab',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        'button-add': '#24932A',
        'left-menu--bg': '#555555',
        'navy-blue-lunacy': '#1976D2', // from sketch footer text
        'orange-btn': '#ee7f00',
        'grey-btn': '#c8c8c8',
      },
    },
  },
});
