export interface Article {
  id: string; // System.Guid
  lieferantenNummer: number; // double
  lieferantName: string; // string
  bilder: string; // string
  masse: string; // string
  bezeichnung: string; // string
  verbandsArtikelNummer: string; // string
  eAN: number; // double
  eKNettoVSK: number; // double
  farbeHerstellerbezeichnung: string; // string
  uVPVME: number; // double
  uVPHerst: number; // double
  herstellerModellName: string; // string
  vEM1: number; // double
  vEM2: number; // double
  vEM3: number; // double
  vENachdispo: number; // double
  rabatt1: number; // double
  preisbindungBis: string; // System.DateTime
  warengruppe: string; // string
  koje: number; // double
  vMEgepflegt: boolean; // bool
  vKText: string; // string
  breite: number; // double
  hoehe: number; // double
  laenge: number; // double
  tiefe: number; // double
  gewicht: number; // double
  import_on: string; // System.DateTime
}

export interface Value {
  value: Article[];
  '@odata.context'?: string | undefined;
  '@odata.count'?: number | undefined;
}

// init api object
function toAPI(data: Partial<Article>): any {
  return {
    Id: data?.id || undefined,
    LieferantenNummer: data?.lieferantenNummer || 0,
    LieferantName: data?.lieferantName || '',
    Bilder: data?.bilder || '',
    Masse: data?.masse || '',
    Bezeichnung: data?.bezeichnung || '',
    VerbandsArtikelNummer: data?.verbandsArtikelNummer || '',
    EAN: data?.eAN || 0,
    EKNettoVSK: data?.eKNettoVSK || 0,
    FarbeHerstellerbezeichnung: data?.farbeHerstellerbezeichnung || '',
    UVPVME: data?.uVPVME || 0,
    UVPHerst: data?.uVPHerst || 0,
    HerstellerModellName: data?.herstellerModellName || '',
    VEM1: data?.vEM1 || 0,
    VEM2: data?.vEM2 || 0,
    VEM3: data?.vEM3 || 0,
    VENachdispo: data?.vENachdispo || 0,
    Rabatt1: data?.rabatt1 || 0,
    PreisbindungBis: data?.preisbindungBis || '',
    Warengruppe: data?.warengruppe || '',
    Koje: data?.koje || 0,
    VMEgepflegt: data?.vMEgepflegt || false,
    VKText: data?.vKText || '',
    Breite: data?.breite || 0,
    Hoehe: data?.hoehe || 0,
    Laenge: data?.laenge || 0,
    Tiefe: data?.tiefe || 0,
    Gewicht: data?.gewicht || 0,
    Import_on: data?.import_on || '',
  };
}

function parse(data?: Partial<Article>): Article {
  return {
    id: data?.id || '',
    lieferantenNummer: data?.lieferantenNummer || 0,
    lieferantName: data?.lieferantName || '',
    bilder: data?.bilder || '',
    masse: data?.masse || '',
    bezeichnung: data?.bezeichnung || '',
    verbandsArtikelNummer: data?.verbandsArtikelNummer || '',
    eAN: data?.eAN || 0,
    eKNettoVSK: data?.eKNettoVSK || 0,
    farbeHerstellerbezeichnung: data?.farbeHerstellerbezeichnung || '',
    uVPVME: data?.uVPVME || 0,
    uVPHerst: data?.uVPHerst || 0,
    herstellerModellName: data?.herstellerModellName || '',
    vEM1: data?.vEM1 || 0,
    vEM2: data?.vEM2 || 0,
    vEM3: data?.vEM3 || 0,
    vENachdispo: data?.vENachdispo || 0,
    rabatt1: data?.rabatt1 || 0,
    preisbindungBis: data?.preisbindungBis || '',
    warengruppe: data?.warengruppe || '',
    koje: data?.koje || 0,
    vMEgepflegt: data?.vMEgepflegt || false,
    vKText: data?.vKText || '',
    breite: data?.breite || 0,
    hoehe: data?.hoehe || 0,
    laenge: data?.laenge || 0,
    tiefe: data?.tiefe || 0,
    gewicht: data?.gewicht || 0,
    import_on: data?.import_on || '',
  };
}

export default {
  parse,
  toAPI: toAPI,
};
