import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/home/Home.vue';
import { ROUTES } from './routesEnum';
import { Logger } from 'fsts';
import AuthorityUtils from '@/router/authorityUtils';

const logger = new Logger('router');
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: ROUTES.home,
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/about/About.vue'),
  },
  {
    path: '/login',
    name: ROUTES.login,
    component: () => import(/* webpackChunkName: "save-lists" */ '../views/login/Login.vue'),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: '/lists',
    name: ROUTES.lists,
    component: () => import(/* webpackChunkName: "save-lists" */ '../views/save-lists/save-lists.vue'),
    meta: {
      requiresAuth: true, // TODO: change to TRUE when login required
    },
  },
  {
    path: '/list/:saveListId',
    name: ROUTES.list,
    component: () => import(/* webpackChunkName: "save-lists" */ '../views/save-lists/save-list/save-list.vue'),
  },
  {
    path: '/system',
    name: ROUTES.system,
    component: () => import(/* webpackChunkName: "save-lists" */ '../views/settings/settings.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

/**
 * Handle role based guarding of routes.
 */
router.beforeEach(async (to: any, from: any, next: any) => {
  // always load api information
  logger.debug(`beforeEach from:${from.path} to:${to.path}`);
  //if route to login then just router does not make any other actions
  if (to.path == 'login') {
    logger.debug('path==login');
    next();
    return;
  }

  // does the route require authorization? if not then just route do not make any other actions
  if (!to.matched.some((record: any) => record.meta.requiresAuth)) {
    logger.debug(`!requiresAuth to:${to.path}`);
    next();
    return;
  }

  try {
    // enforce loading of account details
    // await AuthorityUtils.ensureAccountDetails();

    //user is loggedin
    if (!AuthorityUtils.isEmptyAccount() || !AuthorityUtils.isEmptyAzureAccount()) {
      logger.debug('isLoggedin');
      // check access to route based on roles
      // if (
      //   !AuthorityUtils.isAdmin() &&
      //   !AuthorityUtils.hasAnyRole(to.meta.requiresRole)
      // ) {
      //   logger.debug('!isAdmin && !hasAnyRole');
      //   next('error-access-view');
      //   return;
      // }

      logger.debug(`from:${from.path},to:${to.path}`);
      next(); // all is fine
      return;
    }
  } catch (e) {
    logger.error('error');
  }
  next({
    path: '/login',
    query: { redirect: to.fullPath },
  });
});

export default router;
