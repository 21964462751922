import { ActionTree } from 'vuex';
import { ArticleMarkerState } from './types';
import { RootState } from '../../types';
import { defaultBackendArticleMarker } from '@/shared/backend/articleMarker';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import articleMarker, { Value, ArticleMarker } from '@/shared/model/articleMarker';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';

const logger = new Logger('actions.articleMarkers');
export const actions: ActionTree<ArticleMarkerState, RootState> = {
  getArticleMarkers({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setArticleMarkersIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getArticleMarkersSearchParams;
    return defaultBackendArticleMarker
      .getArticleMarkers(searchParams)
      .then((response: AxiosResponse<Value>) => {
        commit('setArticleMarkers', response.data);
        commit('setArticleMarkersIsLoading', false);
        return response.data;
      })
      .catch((e: any) => {
        commit('setArticleMarkersIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getArticleMarkersLast({ commit, dispatch }, count: number) {
    return defaultBackendArticleMarker
      .getArticleMarkersLast(count)
      .then((response: AxiosResponse<any>) => {
        const result = response.data.$values ? response.data.$values : response.data;
        return result;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getArticleMarker({ commit, dispatch }, id: string) {
    return defaultBackendArticleMarker
      .getArticleMarker(id)
      .then((response: AxiosResponse<ArticleMarker>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateArticleMarker({ commit, dispatch }, file: ArticleMarker) {
    return defaultBackendArticleMarker
      .updateArticleMarker(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ArticleMarker;
          }>
        ) => {
          module_utils.ok(i18n.tc(`success.articleMarker_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteArticleMarker({ commit, dispatch }, id: string) {
    return defaultBackendArticleMarker
      .deleteArticleMarker(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ArticleMarker;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
