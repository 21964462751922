import { GetterTree } from 'vuex';
import { ModelMarkerState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<ModelMarkerState, RootState> = {
  ['getModelMarkers']: (state) => state.modelMarkers,
  ['getModelMarkersIsLoading']: (state) => state.modelMarkers?.isLoading,
  ['getModelMarkersTotal']: (state) => state.modelMarkers?.total,
  ['getModelMarkersSearchParams']: (state) => state.modelMarkers?.searchParams,
};
