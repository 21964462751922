import { ActionTree } from 'vuex';
import { AuthState } from './types';
import { RootState } from '../../types';
import { defaultBackendArticle } from '@/shared/backend/article';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import article, { Value, Article } from '@/shared/model/article';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
import DownloadUtils from '@/shared/utils/DownloadUtils';
import { Credentials } from '@/shared/model/smallPayloadModels/credentials';
import { defaultBackendAccount } from '@/shared/backend/account';

const logger = new Logger('actions.auth');
export const actions: ActionTree<AuthState, RootState> = {
  updateAzureData({ commit }, payload: any) {
    commit('setAzureData', payload);
  },

  async login({ commit, dispatch }, payload: Credentials) {
    // authenticate to get the access token
    return defaultBackendAccount
      .authenticate(payload.username!, payload.password!, payload.rememberMe)
      .then((response: any) => {
        commit('setToken', response.data);
        commit('setAccountId', response.data.userId);
        return response;
      })
      .catch((e: any) => {
        logger.error(e);
        throw e;
      });
  },
  async loadAccountDetails({ commit }, id?: number) {
    return defaultBackendAccount
      .getMyAccount()
      .then((account) => {
        commit('setAccount', account.data.account);
      })
      .catch((e) => {
        commit('setAccount', undefined);
        module_utils.error('', commit, e);
      });
  },

  /**
   * Clears the userType effectively logging out the user.
   */
  logout({ commit }) {
    return defaultBackendAccount.logout().then((result) => {
      commit('setAccount', undefined);
      commit('clearTokens');
      return result;
    });
  },
};
