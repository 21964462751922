import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import articleMarker, { ArticleMarker, Value } from '../model/articleMarker';
import ODataFilterBuilder from 'odata-filter-builder';

const logger = new Logger('backend.ArticleMarker');
export interface BackendArticleMarker {
  getArticleMarker(id: string): AxiosPromise<ArticleMarker>;
  getArticleMarkers: (searchParams: SearchParams) => AxiosPromise<Value>;
  getArticleMarkersLast: (lastCount: number) => AxiosPromise<any>;
  deleteArticleMarker(id: string): AxiosPromise;
  updateArticleMarker(ArticleMarker: ArticleMarker): AxiosPromise<any>;
}

export const defaultBackendArticleMarker: BackendArticleMarker = {
  getArticleMarker(id: string): AxiosPromise<ArticleMarker> {
    let url = `${URLS.articleMarkerOdata}/${id}`;
    return instance.get<ArticleMarker>(url);
  },
  getArticleMarkersLast(lastCount: number): AxiosPromise<ArticleMarker> {
    let url = `${URLS.articleMarker}/GetLastN?lastCount=${lastCount}`;
    return instance.get<ArticleMarker>(url);
  },
  getArticleMarkers(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.articleMarkerOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getArticleMarkers${url}`);
    return instance.get<Value>(url);
  },

  deleteArticleMarker(id: string): AxiosPromise {
    logger.debug('deleteArticleMarker');
    return instance.delete(`${URLS.articleMarker}/${id}`);
  },
  updateArticleMarker(ArticleMarker: ArticleMarker): AxiosPromise<any> {
    logger.debug('updateArticleMarker');
    return instance.put<ArticleMarker>(`${URLS.articleMarker}/update`, articleMarker.toAPI(ArticleMarker));
  },
};
