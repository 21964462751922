
import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import reportDownload, { ReportDownload, Value } from '../model/reportDownload';
import ODataFilterBuilder from 'odata-filter-builder';
import { CONST } from '@/shared/utils/Constants';

const logger = new Logger('backend.ReportDownload');
export interface BackendReportDownload {
  getReportDownload(id: string): AxiosPromise<ReportDownload>;
  getReportDownloads: (searchParams: SearchParams) => AxiosPromise<Value>;
  deleteReportDownload(id: string): AxiosPromise;
  updateReportDownload(ReportDownload: ReportDownload): AxiosPromise<any>;
}

export const defaultBackendReportDownload: BackendReportDownload = {
  getReportDownload(id: string): AxiosPromise<ReportDownload> {
    let url = `${URLS.reportDownloadOdata}/${id}`;
    return instance.get<ReportDownload>(url);
  },
  getReportDownloads(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');
    // odfb.eq(CONST.OrganisationId, organisationId, false);
    let url = DefaultBackendHelper.makeUrl(
      `${URLS.reportDownloadOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getReportDownloads${url}`);
    return instance.get<Value>(url);
  },

  deleteReportDownload(id: string): AxiosPromise {
    logger.debug('deleteReportDownload');
    return instance.delete(`${URLS.reportDownload}/${id}`);
  },
  updateReportDownload(ReportDownload: ReportDownload): AxiosPromise<any> {
    logger.debug('updateReportDownload');
    return instance.put<ReportDownload>(`${URLS.reportDownload}/update`, ReportDownload);
    // return instance.put<ReportDownload>(`${URLS.reportDownload}/update`, reportDownload.toAPI(ReportDownload));
  },
};
