import { Component, Vue } from 'vue-property-decorator';
import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import DateUtils from '@/shared/utils/DateUtils';

const logger = new Logger('home-component');
const articleModule = namespace('article');
const articleMarkerModule = namespace('articleMarker');
const reportDownloadModule = namespace('reportDownload');

@Component({
  components: {
    HelloWorld,
  },
})
export default class Home extends Vue {
  @articleMarkerModule.Action('getArticleMarkersLast')
  private actionGetArticleMarkersLast!: any;

  @articleModule.Action('getArticlesTodayCount')
  private actionGetArticlesTodayCount!: any;
  @articleModule.Action('getArticlesPreisCount')
  private actionGetArticlesTodayPreisCount!: any;
  @articleModule.Action('getArticleLastUpdateDate')
  private actionGetArticleLastUpdateDate!: any;

  @reportDownloadModule.Action('getReportDownloads')
  private actionGetReportDownloads!: any;

  articlesTodayCount = 0;
  articlesLastUpdateDate = '...'; // new Date().toLocaleString('de', { dateStyle: 'short' }) // will show today's date in `dd.mm.yyyy` format
  articleMarkersLast = '...';
  articlesPreisTodayCount = 0;

  reportOutputs: any = [];

  get isRealXl() {
    return this.$vuetify.breakpoint.width < 1920;
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
    }[] = [
      { text: this.$t('created_at'), value: 'createdOn', sortable: false },
      { text: this.$t('created_by'), value: 'createdBy', sortable: false },
      { text: this.$t('name'), value: 'name', sortable: false },
      { text: this.$t('list_type'), value: 'listType', sortable: false },
      { text: this.$t('article_marker'), value: 'articleMarker', sortable: false },
      { text: this.$t('sort'), value: 'sort', sortable: false },
      { text: this.$t('output_type'), value: 'outputType', sortable: false },
      // { text: this.$t('actions'), value: 'updateAt' },
    ];
    return headers;
  }

  get lastArticleMarkers() {
    return this.articleMarkersLast;
  }

  async mounted() {
    await this.getArticleTodayCount();
    await this.getArticleMarkersLast();
    await this.getArticleTodayPreisCount();
    await this.getArticleLastUpdateDate();
    await this.getReportDownloads();
  }

  async getArticleLastUpdateDate() {
    await this.actionGetArticleLastUpdateDate()
      .then((result: any) => {
        logger.log('LastUpdate - getArticleLastUpdateDate backend (UTC):>> ', result);
        this.articlesLastUpdateDate = DateUtils.dbUtcDateTimeToLocalTime(result);
        logger.log('LastUpdate (+ timezone)- getArticleLastUpdateDate backend:>> ', this.articlesLastUpdateDate);
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  async getArticleTodayCount() {
    await this.actionGetArticlesTodayCount()
      .then((result: any) => {
        logger.log('TODAY result getArticleTodayCount :>> ', result);
        this.articlesTodayCount = result;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }
  async getArticleTodayPreisCount() {
    await this.actionGetArticlesTodayPreisCount()
      .then((result: any) => {
        logger.log('result getArticleTodayPreisCount :>> ', result);
        this.articlesPreisTodayCount = result;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  private async getArticleMarkersLast() {
    await this.actionGetArticleMarkersLast(5)
      .then((result: any) => {
        logger.log('result actionGetArticleMarkers :>> ', result);
        this.articleMarkersLast = result.map((x: any) => x.name).join(', ');
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }
  private async getReportDownloads() {
    await this.actionGetReportDownloads()
      .then((result: any) => {
        logger.log('result getReportDownloads :>> ', result);
        this.reportOutputs = result.value;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }
}
