import { LogLevel } from '@azure/msal-browser';
import GeneralUtils from './generalUtils';


const tenantId = GeneralUtils.isDevEnvironment() //|| window.location.host.includes('dev4you')
  ? '2775340b-b251-401c-bcac-e05e5f499a3b'
  : 'f606a097-2d4d-4038-97f3-4287834b31bf';
const clientId = GeneralUtils.isDevEnvironment() //|| window.location.host.includes('dev4you')
  ? '5ee51967-6ecb-4bea-b004-dbd1b766afe5'
  : '0494ea7a-7b2b-4d8d-b082-6c2726aa7763';

export const msalConfig = {
  auth: {
    authority: `https://login.microsoftonline.com/${tenantId}`, // Tenant ID of the React.JS App Registration
    clientId: `${clientId}`, // Client ID
    redirectUri: '/',
    scopes: [`api://${clientId}/api.scope`],
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  // graph: {
  //   url: '<your-graph-api-url>',
  //   scopes: '<your-graph-api-scopes>',
  //   response_type: "blob"
  // },
  mode: 'redirect',
  system: {
    loggerOptions: {
      loggerCallback: (level: any, message: string, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

// Can be found in the API Permissions of the ASP.NET Web API
export const loginApiRequest = {
  scopes: [`api://${clientId}/api.scope`],
};
