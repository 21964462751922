import { ActionTree } from 'vuex';
import { ReportDownloadState } from './types';
import { RootState } from '../../types';
import { defaultBackendReportDownload } from '@/shared/backend/reportDownload';
import { Logger } from 'fsts';
import module_utils from '../module_utils';
import reportDownload, { Value, ReportDownload } from '@/shared/model/reportDownload';
import { AxiosResponse } from 'axios';
import i18n from '@/i18n';
// import { CONST } from '../utils/Constants';

const logger = new Logger('actions.reportDownloads');
export const actions: ActionTree<ReportDownloadState, RootState> = {
  getReportDownloads({ commit, dispatch, getters, rootGetters }, payload?: { searchParams?: any }) {
    commit('setReportDownloadsIsLoading', true);
    let searchParams = payload?.searchParams ?? getters.getReportDownloadsSearchParams;
    return defaultBackendReportDownload
      .getReportDownloads(searchParams)
      .then((response: any) => {
        const result = response.data.$values ? response.data.$values : response.data;
        commit('setReportDownloads', result);
        commit('setReportDownloadsIsLoading', false);
        return result;
      })
      .catch((e: any) => {
        commit('setReportDownloadsIsLoading', false);
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  getReportDownload({ commit, dispatch }, id: string) {
    return defaultBackendReportDownload
      .getReportDownload(id)
      .then((response: AxiosResponse<ReportDownload>) => {
        return response.data;
      })
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  updateReportDownload({ commit, dispatch }, file: any) {
    return defaultBackendReportDownload
      .updateReportDownload(file)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ReportDownload;
          }>
        ) => {
          // module_utils.ok(i18n.tc(`success.reportDownload_update`), commit);
          return response.data;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
  deleteReportDownload({ commit, dispatch }, id: string) {
    return defaultBackendReportDownload
      .deleteReportDownload(id)
      .then(
        (
          response: AxiosResponse<{
            errors: [];
            id: string;
            isValid: boolean;
            result: ReportDownload;
          }>
        ) => {
          return response;
        }
      )
      .catch((e: any) => {
        module_utils.error('error', commit, e, logger);
        throw e;
      });
  },
};
