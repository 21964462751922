import { AxiosPromise } from 'axios';
import { instance } from '.';
import { URLS } from './index';
import { Logger } from 'fsts';
import { SearchParams } from '../model/searchParams';
import { DefaultBackendHelper } from '../utils/backendHelper';
import modelMarker, { ModelMarker, Value } from '../model/modelMarker';
import ODataFilterBuilder from 'odata-filter-builder';

const logger = new Logger('backend.ModelMarker');
export interface BackendModelMarker {
  getModelMarker(id: string): AxiosPromise<ModelMarker>;
  getModelMarkers: (searchParams: SearchParams) => AxiosPromise<Value>;
  deleteModelMarker(id: string): AxiosPromise;
  updateModelMarker(ModelMarker: ModelMarker): AxiosPromise<any>;
}

export const defaultBackendModelMarker: BackendModelMarker = {
  getModelMarker(id: string): AxiosPromise<ModelMarker> {
    let url = `${URLS.modelMarkerOdata}/${id}`;
    return instance.get<ModelMarker>(url);
  },
  getModelMarkers(searchParams: SearchParams): AxiosPromise<Value> {
    var odfb = ODataFilterBuilder('and');

    let url = DefaultBackendHelper.makeUrl(
      `${URLS.modelMarkerOdata}`,
      searchParams.dataOption,
      searchParams.orClauseFieldsIds,
      searchParams.filter,
      odfb
    );
    logger.log(`getModelMarkers${url}`);
    return instance.get<Value>(url);
  },

  deleteModelMarker(id: string): AxiosPromise {
    logger.debug('deleteModelMarker');
    return instance.delete(`${URLS.modelMarker}/${id}`);
  },
  updateModelMarker(ModelMarker: ModelMarker): AxiosPromise<any> {
    logger.debug('updateModelMarker');
    return instance.put<ModelMarker>(`${URLS.modelMarker}/update`, modelMarker.toAPI(ModelMarker));
  },
};
