import { MutationTree } from 'vuex';
import { ModelMarkerState } from './types';
import Vue from 'vue';
import modelMarker, { ModelMarker, Value } from '@/shared/model/modelMarker';

export const mutations: MutationTree<ModelMarkerState> = {
  setModelMarkers(state, payload: Value) {
    state.modelMarkers.items = payload.value.map((x) => modelMarker.parse(x));
    state.modelMarkers.total = payload['@odata.count'] || 0;
    state.modelMarkers.isLoading = false;
  },
  setModelMarkersTotal(state, payload: number) {
    state.modelMarkers.total = payload;
  },
  setModelMarkersIsLoading(state, payload: boolean) {
    state.modelMarkers.isLoading = payload;
  },
};
