export enum CONST {
  emptyGuid = '00000000-0000-0000-0000-000000000000', // emptyGuid is used in Routes when create new entity (localhost:3009/list/00000000-0000-0000-0000-000000000000)
}

// Common statuses for 3 `Article` fields (`Status`, `ModellStatus` and `ModellangebotStatus`)
export enum ArticleStatuses {
  released = 'Released',
  obsolete = 'Obsolete',
  firstRelease = 'FirstRelease',
  releasable = 'Releasable',
  discontinuedVME = 'DiscontinuedVME',
  inactive = 'Inactive',
  releasedAssortment = 'ReleasedAssortment',
}
